.logo {
  width: 140px;
  height: 30px;
}

.login-btn {
  border: none;
  /* margin-top: 8px; */
  width: 110px;
  height: 50px;
  background: #ff5a5f;
  border-radius: 12px;
  /* font-family: bl-regular !important; */
  font-weight: bold;
}

.login-btn:hover {
  background: #ff5a5f;
}
